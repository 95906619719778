html,
body {
  background: #f5f5f5;
}
#root {
  height: 100vh;
}

footer{
  position: absolute;
  bottom: 0;
  width: 100vw;
}
.App {
  /* text-align: start; */
  top: 0px;
  left: 0px;
  width: 100vw;
  opacity: 1;
}
.container {
  border-radius: 15px 15px 0px 0px;
}

.App-logo {
  /* padding: 20px; */
  /* width: 193px; */
  max-height: 140px;
  /* UI Properties */
  opacity: 1;
}

.App-header {
  width: 100%;
  max-height: 140px;
  /* UI Properties */
  background: #0d847c 0% 0% no-repeat padding-box;
  border: 1px solid #0d847c;
  opacity: 1;
}

.pad-2 {
  padding: 2%;
}

.mb-8 {
  margin-bottom: 8%;
}

.d-flex {
  display: flex;
}
.mtop-2{
  margin-top: 2%;
}
.center-div {
  margin-top: 6%;
}
.flex-end {
  justify-content: flex-end;
}

.p-50 {
  padding: 50px;
}

.ml-5
{
  margin-left: 5%;
}

.flex-center {
  justify-content: center;
  align-items: center;
}
.flex-center > div {
  margin: 0 10px;
}
.ft-20 {
  font-size: 20px;
}
.bold {
  font-weight: bold;
}
.get-started {
  margin-top: 10px;
  width: 453px;
  height: 410px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
}
.h1 {
  top: 371px;
  left: 573px;
  height: 28px;
  /* UI Properties */
  text-align: left;
  font: normal normal bold 24px/18px Rubik;
  letter-spacing: 0.24px;
  color: #222222;
  opacity: 1;
}
.login {
  width: 453px;
  height: 410px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
}

.login-input {
  border: none;
  border-bottom: 1px solid #0d847c;
  outline: none;
  border-radius: 0;
}

.login-input::placeholder {
  color: black;
  font-size: 16px;
}
.error {
  color: red;
}
.register,.dashboard-panel {
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
}

.register {
  width: 453px;
}

.dashboard-panel{ 
  width: 1000px;
}

.word-break {
  word-break: break-all;
}
.bold{
  font-weight:450;
}
.w-1000{
  max-width: 1000px;
}
.font-17{
  font-size: 17px;
}

.space-between {
  justify-content: space-between;
}
.grey {
  color: lightgrey;
}

.App-link {
  color: #61dafb;
}

.btn-dashboard,
.mail-text {
  color: #0d847c;
  background: transparent;
  border: none;
}
.btn-dashboard,
.mail-text,
.font-16 {
  font-size: 16px;
}
.btn-success,
.btn-success:visited {
  background-color: #0d847c;
  border-color: #0d847c;
}
.btn-success:active,
.btn-success:hover {
  background-color: #096b64;
}

.btn-outline-success {
  border-color: #0d847c;
  color: #0d847c;
}

.btn-outline-success:hover,
.btn-outline-success:active,
.btn-outline-success:visited {
  background-color: #0d847c;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#errorMess {
  color: #d8000c;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.main-container {
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: calc(100% - 204px);
  padding-left: 0;
  padding-right: 0;
  width: 100%;
  max-width: unset;
  padding-top: 16px;
  padding-bottom: 16px;
}

.main-div-height {
  min-height: calc(100% - 204px);
}
