.copyright {
  /* padding: 28px 0; */
  /* margin-top: 55px; */
  background-color: #1E2838;
}
.copyright span,
.copyright a {
  color: #ffffff;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.copyright a {
      text-decoration: underline;
}
.copyright a:hover {
  color: #007bff;
}

.plr-4{
    padding: 0 4%;
}