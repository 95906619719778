* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  max-width: 100vw;
}
header.sticky {
  /* position: fixed; */
  top: 0;
  left: 0;
  width: 100%;
  padding: 40px 100px;
  padding-top: 24px;
  padding-bottom: 0px;
  padding-left: 100px;
  padding-right: 100px;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.5s;
  background: #0d847c;
  padding: 20px 100px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  color: #111;
}
header.sticky ul li a {
  color: #fff;
}

header.sticky .logo {
  color: #fff;
  margin-left: -15%;
  font-size: 24px;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 700;
  letter-spacing: 2px;
  position: absolute;
  left: 18%;
}

header.sticky ul {
  position: relative;
  display: flex;
}
header.sticky ul li {
  position: relative;
  list-style: none;
  align-self: center;
  text-align: start;
}
header.sticky ul li a {
  position: relative;
  display: inline-block;
  margin: 0 15px;
  color: #fff;
  text-decoration: none;
}

header.sticky > ul > li:hover {
  color: #0d847c;
  font-weight: 700;
}
@media (max-width: 991px) {
  header,
  header.sticky {
    padding: 10px 50px;
    z-index: 1000;
  }
  header.sticky ul {
    position: fixed;
    display: block;
  }
  .menu {
    position: fixed;
    top: 60px;
    left: -100%;
    display: block;
    padding: 100px 50px;
    text-align: center;
    width: 50%;
    height: 100vh;
    background: #fff;
    transition: 0.5s;
    z-index: 999;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 10px 5px 20px rgba(0, 0, 0, 0.1);
  }
  .menu.active {
    left: 0;
  }
  header.sticky ul li a {
    color: #000 !important;
  }
  header.sticky > ul > li > a:hover {
    color: #0d847c;
  }
  header.sticky .logo {
    margin-left: auto;
    margin-right: auto;
  }

  .toggle {
    width: 40px;
    height: 40px;
    background: url(./utils/menu.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 30px;
    cursor: pointer;
  }

  .toggle.active {
    background: url(./utils/close.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 25px;
    cursor: pointer;
  }
}

